<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">고객정보목록</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
              :items="dropItems"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_CUST_KEY"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                @change="startDate"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                @change="endDate"
                show-current="false"
                :min="dates[0]"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="고객 ID"
            outlined
            hide-details
            v-model="CUSTOMER_ID"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="고객 이름"
            outlined
            hide-details
            v-model="CUSTOMER_NM"
          >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')" dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain @click="findCus">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" @click="resetCus"
            plain
            >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 " v-on:click="[flagGetList=true, getList()]"
            >조회</v-btn
          >
        </div>
      </div>
    </div>
    <!-- 고객정보목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">고객정보목록</h2>

      <div class="box-ct">
        <v-data-table
          class="grid-default"
          height="277px"
          fixed-header
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="id"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          v-model="itemSelectList"
          :item-class="isActiveRow"
          @click:row="rowClick"
          show-select
        >
        </v-data-table>

        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!--// 고객정보목록 -->
    <!-- 고객상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        고객상세정보
        <div class="ml-auto">
          <!-- <v-btn outlined small class="btn-default">추가</v-btn> -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined  class="btn-etc ml-2" @click="deleteBtn"
            >삭제</v-btn
          >
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined  class="btn-point ml-2" @click="insertBtn"
            >저장</v-btn
          >
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              고객상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="291px" />
              <col width="127px" />
              <col width="291px" />
              <col width="127px" />
              <col width="291px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">고객ID</v-badge>
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    placeholder="고객ID"
                    outlined
                    hide-details
                    disabled
                    filled
                    v-model="CUSTOMER_ID_DETAIL"
                  />
                </td>
                <th scope="row">고객명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    placeholder="고객명"
                    outlined
                    hide-details
                    v-model="CUSTOMER_NM_DETAIL"
                  />
                </td>
                <th scope="row">상담대상</th>
                <td>
                  <div class="form-inp sm">
                    <v-select
                      :items="dropData.dropType"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="구분"
                      v-model="CUSTOMER_TYPE_DETAIL"
                    ></v-select>
                  </div>

                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="dropData.dropGender"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="성별"
                      v-model="CUSTOMER_GENDER_DETAIL"
                    ></v-select>
                  </div>
                </td>
                <th scope="row" rowspan="4">메모</th>
                <td rowspan="4">
                  <v-textarea
                    class="textarea-type0"
                    height="188px"
                    label=""
                    :rules="textareaRules"
                    :value="textareaValue"
                    no-resize
                    outlined
                    v-model="MEMO"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">지역</th>
                <td>
                  <div class="form-inp full">
                    <v-select
                      :items="dropData.dropRegion"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택"
                      v-model="CUSTOMER_REGION_DETAIL"
                    ></v-select>
                    <!--<input type="text" maxlength="40" v-model="CUSTOMER_REGION_DETAIL"/>-->
                  </div>
                </td>
                <th scope="row">출생년도(나이)</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    name="birth"
                    placeholder="출생년도"
                    outlined
                    hide-details
                    maxlength="4"
                    @keyup="changeAge(CUSTOMER_BIRTH_DETAIL)"
                    v-model="CUSTOMER_BIRTH_DETAIL"
                  />
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="originAge"
                    label=""
                    outlined
                    disabled
                    filled
                    hide-details
                    v-model="CUSTOMER_AGE_DETAIL"
                  />
                </td>
                <th scope="row">학업/재직 상태</th>
                <td>
                  <div class="form-inp full">
                    <v-select
                      :items="dropData.dropCareer"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택"
                      v-model="CUSTOMER_CAREER_STATUS_DETAIL"
                    ></v-select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">최종학력(전공)</th>
                <td colspan="5">
                  <div class="d-inline-flex align-center">
                    <v-radio-group v-model="eduRadioYN" row class="radio-inline">
                      <v-radio
                        value="Y"
                        label="확인"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                        @click="eduRadBtnYN(eduRadioYN)"
                      ></v-radio>
                    </v-radio-group>
                    <v-select
                      class="form-inp sm ml-2"
                      :items="dropData.dropEduLevel"
                      :disabled="isEduReadOnly"
                      :filled="isEduReadOnly"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="최종학력"
                      v-model="CUSTOMER_EDU_LEV_DETAIL"
                    ></v-select>
                    <v-select
                      class="form-inp sm ml-2"
                      :items="dropData.dropEduMajor"
                      :disabled="isEduReadOnly"
                      :filled="isEduReadOnly"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="전공계열"
                      v-model="CUSTOMER_EDU_MAJOR_DETAIL"
                    ></v-select>
                    <v-radio-group v-model="eduRadioYN" row class="radio-inline ml-2">
                      <v-radio
                        value="N"
                        label="미확인"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                        @click="eduRadBtnYN(eduRadioYN)"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">참여유무</th>
                <td colspan="5">
                  <div class="d-inline-flex align-center">
                    <v-radio-group v-model="joinRadioYN" row class="radio-inline">
                      <v-radio
                        value="Y"
                        label="있음"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                        @click="joinRadBtnYN(joinRadioYN)"
                      ></v-radio>
                    </v-radio-group>

                    <v-text-field
                      class="form-inp sm ml-2"
                      name="name"
                      :disabled="isJoinReadOnly"
                      :filled="isJoinReadOnly"
                      outlined
                      hide-details
                      v-model="CUSTOMER_JOIN_POLICY_DETAIL"
                    />

                    <v-radio-group v-model="joinRadioYN" row class="radio-inline ml-2">
                      <v-radio
                        value="N"
                        label="없음"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                        @click="joinRadBtnYN(joinRadioYN)"
                      ></v-radio>
                    </v-radio-group>

                    <v-radio-group v-model="joinRadioYN" row class="radio-inline ml-2">
                      <v-radio
                        value="U"
                        label="미확인"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                        @click="joinRadBtnYN(joinRadioYN)"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 고객상세정보 -->

    <!-- 고객정보 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogMP01"
        content-class="square-modal"
      >
        <dialog-MP01
            headerTitle="고객정보"
            @hide="hideDialog('MP01')"
            :cusInfo="cusInfo"
            @input="cusInfo = $event"
            @selectCus="[hideDialog('MP01'), setCus()]"
        >
        </dialog-MP01>
        <template v-slot:body>

        </template>
      </v-dialog>
    </div>
    <!-- 고객정보 모달창 -->
  </div>
</template>

<script>
import api from "@/store/apiUtil.js";
import DialogMP01 from "./MP01.vue";
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_M810201", //name은 'MENU_' + 파일명 조합
  components: {
    DialogMP01
  },
  mixins:[mixin],
  data() {
    return {
      DialogMP01:false,
      cusInfo:{},

      // 고객상세정보 라디오버튼 disable 설정 변경 변수
      eduRadioYN: "",
      joinRadioYN: "",
      isEduReadOnly: false,
      isJoinReadOnly: false,

      // api.post 파라미터 보낼 변수
      URLData: "",
      headers: {},
      sendData: {},

      menu1: false,

      // 초기 조회 시 1번 row flag
      flagGetList : false,

      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      alertMsg: {
        succ: "정상적으로 처리되었습니다.",
        err: "시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.",
        comChk: "회사구분란에서 회사를 선택 후 이용해주십시오.",
        cusChk: "고객상세정보를 확인 후 이용해주십시오.",
        delChk: "삭제 하시겠습니까?",
        zeroDelChk: "체크된 항목이 존재하지 않습니다.",
        overDelChk: "하나의 항목만 삭제할 수 있습니다.",
      },

      alertIcon: {
        noti: "svg-done-lg",
        err: "svg-error-lg",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },

      // 셀렉트박스 입력 변수
      dropData: {
        dropType: [],
        dropGender: [],
        dropRegion: [],
        dropCareer: [],
        dropEduLevel: [],
        dropEduMajor: [],
      },
      dropItems: [

      ],

      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "70px",
          sortable: true,
        },
        {
          text: "회사명",
          value: "ASP_NEWCUST_KEY_NM",
          align: "center",
          sortable: true,
          width: '120px',
        }, //COMPANY_NM
        {
          text: "전화고객ID",
          value: "CUSTOMER_ID",
          align: "center",
          sortable: true,
        },
        {
          text: "채팅고객ID",
          value: "TALK_USER_KEY",
          align: "center",
          sortable: true,
        },
        {
          text: "고객명",
          value: "CUSTOMER_NM",
          align: "center",
          sortable: true,
          width: '100px',
        },
        {
          text: "상담대상",
          value: "CUSTOMER_TYPE_NM",
          align: "center",
          sortable: true,
          width: '100px',
        }, //ASP_CUST_KEY
        {
          text: "지역",
          value: "CUSTOMER_REGION_NM",
          align: "center",
          sortable: true,
          width: '100px',
        },
        {
          text: "성별",
          value: "CUSTOMER_GENDER_NM",
          align: "center",
          sortable: true,
          width: '80px',
        },
        {
          text: "출생년도",
          value: "CUSTOMER_BIRTH",
          align: "center",
          sortable: true,
          width: '120px',
        },
        {
          text: "학업및재직상태",
          value: "CUSTOMER_CAREER_STATUS_NM",
          align: "center",
          sortable: true,
          width: '150px',
        },
        {
          text: "최종학력",
          value: "CUSTOMER_EDU_LEV_NM",
          align: "center",
          sortable: true,
          width: '120px',
        },
        {
          text: "전공계열",
          value: "CUSTOMER_EDU_MAJOR_NM",
          align: "center",
          sortable: true,
          width: '120px',
        },
      ],

      gridDataText: [],
      gridDataSelect: [],
      itemSelectList: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      ASP_CUST_KEY: "",
      CUSTOMER_ID: "",
      CUSTOMER_NM: "",

      //고객상세정보
      ASP_CUST_KEY_DETAIL: "",
      CUSTOMER_ID_DETAIL: "",
      CUSTOMER_NM_DETAIL: "",
      CUSTOMER_TYPE_DETAIL: "",
      CUSTOMER_GENDER_DETAIL: "",
      MEMO: "",

      CUSTOMER_REGION_DETAIL: "",
      CUSTOMER_BIRTH_DETAIL: "",
      CUSTOMER_AGE_DETAIL: "",
      CUSTOMER_CAREER_STATUS_DETAIL: "",

      CUSTOMER_EDU_CHK_YN_DETAIL : "",
      CUSTOMER_EDU_CHK_Y_DETAIL: "",
      CUSTOMER_EDU_LEV_DETAIL: "",
      CUSTOMER_EDU_MAJOR_DETAIL: "",
      CUSTOMER_EDU_CHK_N_DETAIL: "",

      CUSTOMER_JOIN_POLICY_DETAIL: "",
      CUSTOMER_JOIN_POLICY_YN_DETAIL:"",
      // CUSTOMER_JOIN_POLICY_Y_DETAIL: '',
      // CUSTOMER_JOIN_POLICY_N_DETAIL: '',
      // CUSTOMER_JOIN_POLICY_D_DETAIL: '',

      selectedRow: null,
    };
  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },

    endDate(e) {
      this.dates[1] = e;
    },

    //*********************************
    //      셀렉트박스 데이터 주입
    //*********************************
    setDropDown(data) {
      this.dropData.dropType     = data.TALK021;
      this.dropData.dropGender   = data.TALK022;
      this.dropData.dropRegion   = data.TALK023;
      this.dropData.dropCareer   = data.TALK024;
      this.dropData.dropEduLevel = data.TALK025;
      this.dropData.dropEduMajor = data.TALK026;
    },

    //*********************************
    //    출생년도 변경 시 만 나이 변경
    //*********************************
    changeAge(birth) {
      if (birth.length == 4) {
        this.CUSTOMER_AGE_DETAIL = this.ageDetail(birth);
      } else {
        this.CUSTOMER_AGE_DETAIL = "";
      }
    },

    //******************************************************************
    // userDetail 만 나이 계산
    // (월, 일 data가 없어서 실질적 만 나이 계산 불가 => ASIS와 같은 방향으로 만듦)
    //******************************************************************
    ageDetail(birth) {
      const today = new Date();

      let inputBirth = Number(birth);
      let minBirth = Number(today.getFullYear()) - 150;
      let maxBirth = Number(today.getFullYear());

      if (inputBirth >= minBirth && birth <= maxBirth) {
        let age = today.getFullYear() - birth;
        age = "만" + age + "세";

        // == 실제 만 나이 계산 코드 ==
        // const today = new Date();
        // const birthDate = new Date(2000, 01, 31);
        //
        // let age = today.getFullYear() - birthDate.getFullYear();
        // const m = today.getMonth() - birthDate.getMonth();
        // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //   age--;
        // }

        return age;
      }

      return "";
    },

    //*********************************
    //        고객 리스트 조회
    //*********************************
    getList() {
      if (this.mixin_isEmpty(this.ASP_CUST_KEY)) {
        this.common_alert(this.alertMsg.comChk, "error")
        return false;
      }

      // URLData, headerData 셋팅
      this.URLData = "";
      this.URLData = "/api/setting/customer/info-list/page/inqire";

      this.headers = {};
      this.headers["SERVICE"] = "setting.customer.info-list.page";
      this.headers["METHOD"] = "inqire";
      this.headers["GRID_ID"] = "divGrid01";
      this.headers["ASYNC"] = false;
      this.headers["TYPE"] = "BIZ_SERVICE";
      (this.headers["ROW_CNT"] = 50), (this.headers["PAGES_CNT"] = 1);

      // sendData 초기화 및 셋팅
      this.sendData = {};
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_CUST_KEY; // 회사코드, 현재 user ID로 회사코드를 불러 올 수 없어서 설정함
      this.sendData["SEARCH_CUSTOMER_ID"] = this.CUSTOMER_ID;
      this.sendData["SEARCH_CUSTOMER_NM"] = this.CUSTOMER_NM;
      this.sendData["REG_DTTM_FROM"] = this.dates[0];
      this.sendData["REG_DTTM_TO"] = this.dates[1];

      //"ASP_NEWCUST_KEY": this.ASP_CUST_KEY,
      //"CUSTOMER_ID": this.CUSTOMER_ID,
      //"CUSTOMER_NM": this.CUSTOMER_NM,

      // this.divGrid01Page = 1;
      // this.divGrid01PageCount = 0;
      // this.divGrid01ItemsPerPage = 100;
      // this.divGrid01TotalVisible = 10;
      // this.divGrid01DataText = [];

      this.result();
    },

    //*********************************
    //      고객상세정보 저장(수정)
    //*********************************
    insertBtn() {
      if (this.mixin_isEmpty(this.CUSTOMER_ID_DETAIL)) {
        this.common_alert(this.alertMsg.cusChk, "chk")
        // this.$store.commit("alertStore/openAlertDialog", {
        //   alertDialogToggle: true,
        //   msg: this.alertMsg.cusChk,
        //   iconClass: this.alertIcon.noti,
        //   type: this.alertType.default,
        // });
        return false;
      }

      // URLData, headerData 셋팅
      this.URLData = "";
      this.URLData = "/api/setting/customer/info-list/regist";

      this.headers = {};
      this.headers["SERVICE"] = "setting.customer.info-list";
      this.headers["METHOD"]  = "regist";
      this.headers["ASYNC"]   = false;
      this.headers["TYPE"]    = "BIZ_SERVICE";

      // sendData 초기화 및 셋팅
      this.sendData = {};
      this.sendData["PROC_ID"]            = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.sendData["TALK_USER_KEY"]      = this.gridDataSelect.TALK_USER_KEY;
      this.sendData["ASP_NEWCUST_KEY_ID"] = this.gridDataSelect.ASP_NEWCUST_KEY;

      this.sendData["CUSTOMER_ID"]     = this.CUSTOMER_ID_DETAIL;
      this.sendData["CUSTOMER_NM"]     = this.restoreXSS_CKeditorDec(this.CUSTOMER_NM_DETAIL);
      this.sendData["CUSTOMER_TYPE"]   = this.CUSTOMER_TYPE_DETAIL;
      this.sendData["CUSTOMER_GENDER"] = this.CUSTOMER_GENDER_DETAIL;
      this.sendData["CUSTOMER_MEMO"]   = this.restoreXSS_Dec(this.MEMO);
      //this.sendData["CUSTOMER_MEMO"]   = this.MEMO;

      this.sendData["CUSTOMER_REGION"]        = this.CUSTOMER_REGION_DETAIL;
      this.sendData["CUSTOMER_BIRTH"]         = this.CUSTOMER_BIRTH_DETAIL;
      this.sendData["CUSTOMER_AGE"]           = this.CUSTOMER_AGE_DETAIL;
      this.sendData["CUSTOMER_CAREER_STATUS"] = this.CUSTOMER_CAREER_STATUS_DETAIL;

      this.sendData["CUSTOMER_EDU_CHK_YN"] = this.CUSTOMER_EDU_CHK_YN_DETAIL;
      this.sendData["CUSTOMER_EDU_CHK_Y"]  = "";
      this.sendData["CUSTOMER_EDU_LEV"]    = this.CUSTOMER_EDU_LEV_DETAIL;
      this.sendData["CUSTOMER_EDU_MAJOR"]  = this.CUSTOMER_EDU_MAJOR_DETAIL;
      this.sendData["CUSTOMER_EDU_CHK_N"]  = "";

      this.sendData["CUSTOMER_JOIN_POLICY_YN"] = this.CUSTOMER_JOIN_POLICY_YN_DETAIL;
      this.sendData["CUSTOMER_JOIN_POLICY_Y"]  = "";
      this.sendData["CUSTOMER_JOIN_POLICY"]    = this.restoreXSS_CKeditorDec(this.CUSTOMER_JOIN_POLICY_DETAIL);
      this.sendData["CUSTOMER_JOIN_POLICY_N"]  = "";
      this.sendData["CUSTOMER_JOIN_POLICY_D"]  = "";

      this.sendData["ASP_NEWCUST_KEY"] = this.gridDataSelect.ASP_CUST_KEY;

      this.result();
    },

    //*********************************************
    //        고객정보목록 중 체크값 삭제 alert
    //*********************************************
    deleteBtn() {
      if (this.itemSelectList.length == 0) {
        this.common_alert(this.alertMsg.zeroDelChk, "error")
        return false;
      } else if (this.itemSelectList.length == 1) {
        this.common_confirm(this.alertMsg.delChk,
            this.delConfirm,null, this.closeAlert, null, "error")

      } else {
        this.common_alert(this.alertMsg.overDelChk, "error")
        return false;
      }
    },

    closeAlert() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    //*********************************************
    //        고객정보목록 중 체크값 삭제 실행
    //*********************************************
    delConfirm() {
      // URLData, headerData 셋팅
      this.URLData = "";
      this.URLData = "/api/setting/customer/info-list/delete";

      this.headers = {};
      this.headers["SERVICE"] = "setting.customer.info-list";
      this.headers["METHOD"]  = "delete";
      this.headers["ASYNC"]   = false;
      this.headers["TYPE"]    = "BIZ_SERVICE";

      // sendData 초기화 및 셋팅
      this.sendData = {};
      for (let i in this.itemSelectList) {
        this.sendData["ASP_NEWCUST_KEY"] = this.itemSelectList[i].ASP_NEWCUST_KEY;
        this.sendData["TALK_USER_KEY"]   = this.itemSelectList[i].TALK_USER_KEY;
        this.sendData["CUSTOMER_ID"]     = this.itemSelectList[i].CUSTOMER_ID;
      }

      // itemSelectList v-model 초기화
      this.itemSelectList = [];

      this.result();
    },

    //********************************************
    //            데이터 불러오기(post)
    //********************************************
    async result() {
      //api 호출 부분
      await api
        .post(
          this.URLData, //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{

          this.sendData, //api에서 사용될 data 입력

          { head: this.headers }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)

        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == true) {
            this.common_alert(this.alertMsg.err, "error")
            return false;
          }

          switch (response.data.HEADER.URL) {
            case "/api/code/common/code-book/inqry":
              //this.dropData = response.data;
              this.setDropDown(response.data);

              break;

            case "/api/setting/customer/info-list/page/inqire":
              //this.gridDataText = response.data.DATA;
              let tempDataText = response.data.DATA;

              // DB에서 불러온 data에 index가 없어서 임시로 사용
              let idx = 1;

              for (let i in tempDataText) {
                let id = response.data.DATA[i].CUSTOMER_ID;
                tempDataText[i]["id"] = id;
                tempDataText[i]["index"] = idx++;
                tempDataText[i]["CUSTOMER_NM"] =
                    this.restoreXSS_CKeditor(tempDataText[i]["CUSTOMER_NM"]);
                tempDataText[i]["CUSTOMER_JOIN_POLICY"] =
                    this.restoreXSS_CKeditor(tempDataText[i]["CUSTOMER_JOIN_POLICY"]);
              }

              this.gridDataText = tempDataText;
              if(this.flagGetList) {
                this.rowClick(tempDataText[0]);
                this.flagGetList = false;
              }
              break;

            case "/api/setting/customer/info-list/regist":
              this.common_alert(this.alertMsg.succ, "done")
              this.getList();

              break;

            case "/api/setting/customer/info-list/delete":
              this.common_alert(this.alertMsg.succ, "done")
              this.flagGetList = true;
              this.getList();
              break;

            default:
              break;
          }
        }) //api 호출 성공 이후 수행

        .catch((err) => {
          alert(err);
        }); //api호출 에러 작업 수행
    },

    //userTable row 클릭이벤트
    rowClick(e, row) {
      if(row != undefined) this.selectedRow = row.item.index;
      this.gridDataSelect = e;

      this.ASP_CUST_KEY_DETAIL = e.ASP_CUST_KEY;
      this.CUSTOMER_ID_DETAIL  = e.CUSTOMER_ID; //고객ID
      this.CUSTOMER_NM_DETAIL  = e.CUSTOMER_NM; //고객명
      this.CUSTOMER_TYPE_DETAIL = e.CUSTOMER_TYPE; //상담대상
      this.CUSTOMER_GENDER_DETAIL = e.CUSTOMER_GENDER; //성별
      this.MEMO = this.restoreXSS(e.CUSTOMER_MEMO); //메모

      this.CUSTOMER_REGION_DETAIL = e.CUSTOMER_REGION; //지역
      this.CUSTOMER_BIRTH_DETAIL = e.CUSTOMER_BIRTH; //출생년도
      this.CUSTOMER_AGE_DETAIL = this.ageDetail(this.CUSTOMER_BIRTH_DETAIL); //만 나이
      this.CUSTOMER_CAREER_STATUS_DETAIL = e.CUSTOMER_CAREER_STATUS; //학업/재직 상태

      this.eduRadioYN = this.eduRadBtnYN(e.CUSTOMER_EDU_CHK_YN); //최종학력(전공) YN
      this.CUSTOMER_EDU_LEV_DETAIL = e.CUSTOMER_EDU_LEV; //최종학력
      this.CUSTOMER_EDU_MAJOR_DETAIL = e.CUSTOMER_EDU_MAJOR; //전공

      this.joinRadioYN = this.joinRadBtnYN(e.CUSTOMER_JOIN_POLICY_YN);
      this.CUSTOMER_JOIN_POLICY_DETAIL = e.CUSTOMER_JOIN_POLICY;

      // this.CUSTOMER_JOIN_POLICY_Y_DETAIL = item.a;
      // this.CUSTOMER_JOIN_POLICY_N_DETAIL = item.a;
      // this.CUSTOMER_JOIN_POLICY_D_DETAIL = item.a;
    },

    isActiveRow(item){
      const activeClass = item.index === this.selectedRow ? 'active' : '';
      return activeClass;
    },

    // param이 'Y'-> disable 비활성화, 'N'-> disable 활성화
    eduRadBtnYN(param) {
      switch (param) {
        case "Y":
          this.isEduReadOnly = false;
          this.CUSTOMER_EDU_CHK_YN_DETAIL = "Y"
          break;

        case "N":
          this.isEduReadOnly = true;
          this.CUSTOMER_EDU_CHK_YN_DETAIL = "N"
          break;

        case "":
          this.isEduReadOnly = true;
          this.CUSTOMER_EDU_CHK_YN_DETAIL = "N"
          break;

        default:
          this.isEduReadOnly = true;
          break;
      }
      return param;
    },

    // ***********************************************************************
    // 라디오버튼 활성화에 따른 textBox disable 속성 변경
    // param이 'Y'-> disable 비활성화, 'N'-> disable 활성화
    // ***********************************************************************
    joinRadBtnYN(param) {
      switch (param) {
        case "Y":
          this.isJoinReadOnly = false;
          this.CUSTOMER_JOIN_POLICY_YN_DETAIL = "Y";
          break;

        case "N":
          this.isJoinReadOnly = true;
          this.CUSTOMER_JOIN_POLICY_YN_DETAIL = "N";
          break;

        default:
          this.isJoinReadOnly = true;
          this.CUSTOMER_JOIN_POLICY_YN_DETAIL = "U";
          return "U";
      }
      return param;
    },

    //*******************************************************
    //      get 셀렉트박스 리스트(추후 공통으로 변경 예정?)
    //*******************************************************
    getSelectList() {
      this.URLData = "/api/code/common/code-book/inqry";

      this.headers["SERVICE"] = "code.common.code-book";
      this.headers["METHOD"] = "inqry";
      this.headers["ASYNC"] = false;
      this.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 초기화 및 세팅
      this.sendData = {};
      let sendList = [];

      sendList.push({ GROUP_CD: "TALK021" });
      sendList.push({ GROUP_CD: "TALK022" });
      sendList.push({ GROUP_CD: "TALK023" });
      sendList.push({ GROUP_CD: "TALK024" });
      sendList.push({ GROUP_CD: "TALK025" });
      sendList.push({ GROUP_CD: "TALK026" });

      this.sendData["GROUP_CD"] = sendList;
      this.result();
    },

    //**********************************************************************
    //        user(상담사) 아이디 연동하여 회사 정보(이름, code 받아오기)
    //**********************************************************************
    getUserCompanyCd() {
      this.URLData = "/api/code/common/code-book/aspinqry";

      this.headers["SERVICE"] = "code.common.code-book";
      this.headers["METHOD"] = "aspinqry";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData = {};
      this.sendData["ASP_USER_ID"] =
      this.$store.getters["userStore/GE_USER_ID"];

      this.result();
    },

    showDialog(menu) {
      this[`Dialog${menu}`] = true;
    },

    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    //*********************************
    //        고객 검색 초기화
    //*********************************
    resetCus(){
      this.CUSTOMER_ID = "";
      this.CUSTOMER_NM = "";
    },

    //*********************************
    //         고객 검색 팝업
    //*********************************
    findCus(){
      if (this.mixin_isEmpty(this.ASP_CUST_KEY)) {
        this.common_alert(this.alertMsg.comChk, "error")
        return false;
      }

      var cust = {ASP_CUST_KEY:this.ASP_CUST_KEY};
      this.cusInfo = cust;

      this.showDialog('MP01');
    },

    setCus(){
      this.CUSTOMER_ID = this.cusInfo.CUST_NO;
      this.CUSTOMER_NM = this.cusInfo.CUST_NM;
    },
  },
  mounted() {
    console.log("init 실행~~");

    this.getSelectList();
    //this.getUserCompanyCd();
    this.dropItems = this.$store.getters['userStore/GE_USER_COMPANY'];
  },

  computed: {
    dateRangeText() {
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
